<template>
  <v-container :id="faq.slug" class="pa-0 pt-16 pb-16">
    <v-row>
      <v-col id="scrollTarget">
        <H2Number
          :number="faq.title_has_number ? `${faq.title_number}` : ''"
          :title="`${activeTranslationWrapper?.title}`"
          :class="{ 'text-center': mdAndUp }" />
      </v-col>
    </v-row>
    <ClientOnly>
      <v-row class="d-flex flex-row justify-center px-0 pb-2 mt-n4">
        <v-col cols="12">
          <v-expansion-panels v-model="openPanelIndex">
            <v-expansion-panel
              v-for="(entry, index) in activeTranslationWrapper?.faq_entry"
              :key="index"
              :class="{ 'custom-margin-top-0': openPanelIndex === -1 }"
              elevation="0"
              bg-color="transparent">
              <CdeCard rounded="xl" class="mt-4">
                <v-expansion-panel-title hide-actions class="py-5">
                  <FaqButton
                    class="rounded-md text-white mr-4"
                    :color="openPanelIndex !== index ? 'primary' : 'primary-lighten'"
                    :icon="openPanelIndex !== index ? 'mdi-plus' : 'mdi-minus'"
                    rounded="lg" />
                  <p>
                    <strong class="font-weight-bold text-start">{{ entry.question }}</strong>
                  </p>
                </v-expansion-panel-title>
                <v-expansion-panel-text class="bg-surface-darken2 pt-0">
                  <div v-html="sanitizeHtml(entry.answer_text)" />
                </v-expansion-panel-text>
              </CdeCard>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </ClientOnly>
  </v-container>
</template>

<script lang="ts" setup>
import type { LocaleObject } from '@nuxtjs/i18n'
import { useDisplay } from 'vuetify'
import type { Faq, FaqTranslation } from '~/types/types'
import { sanitizeHtml } from '~/util/sanitizer'

const { locale, locales } = useI18n()

const { mdAndUp } = useDisplay()

const props = defineProps<{ faq: Faq }>()

const faqPalyoad = props.faq

const currentLocaleIso = computed(() => {
  return locales.value?.find((l: LocaleObject) => l.code === locale.value)?.language as string
})

const activeTranslationWrapper = computed(() => {
  return faqPalyoad.translations?.find(
    (t: FaqTranslation) => t.languages_code === currentLocaleIso.value,
  )
})

const openPanelIndex = ref(-1)
</script>

<style lang="scss" scoped>
:deep(.v-expansion-panel-title > span, .v-expansion-panel-title__overlay) {
  display: none !important;
}

.v-expansion-panel-title {
  justify-content: flex-start;
}

.v-expansion-panel:not(:first-child)::after {
  border: none !important;
}

.custom-margin-top-0 {
  margin-top: 0px !important;
}
</style>
